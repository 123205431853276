.carousel-container{
    padding: 5px;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel__poster{
    margin: 10px !important;
}
.carousel-viewport{
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.carousel-viewport::-webkit-scrollbar{
    display: none;
}
.carousel-nav{
    width:35px;
}
.carousel-button{
    display:flex;
    justify-content: center;
    align-items: center;
}
.carousel-button button{
    border: none;
    outline: none;
    background-color: inherit;
    color: white;
    font-size: 2rem;
}
.trailer-component{
    margin: 10px;
}

@media(min-height: 800px){
    .carousel-button button{
        font-size: 1.2rem;
    }
}