.poster-container{
    height: 98vh;
    width: 100%;
    position: relative;
}
#trailer-button{
    padding:10px 30px;
    border: none;
    border-radius: 20px;
    background: #FF0065;
    color: white;
    font-size: 1.5rem;
    margin: 10px 0px 50px 10px;
}
#trailer-button:hover{
    color: #FF0065;
    background: white;
    filter: brightness(90%);
}
#poster-arrow-left{
    position: absolute;
    top: 10px;
    padding: 20px;
}
section{
    padding: 10px 100px;
}
.poster-name{
    padding: 40vh 10vw;
    text-align: center;
    color: rgba(255, 255, 255, 0.666)
}
.poster-info-container{
    margin-top: 30px;
}
.poster-name h1{
    font-size: 3em;
    opacity: 0;
    animation: slideIn 1s forwards;
    animation-delay: .3s;    
}
.poster-name span{
    font-size: 1.5em;
    color: yellow;
    animation: ratingSlide 1s forwards;
    animation-delay: 0.6s;
    opacity:0;    
}
.poster-info{
    display:flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.poster-poster img{
    width: 205px;
    height: 298px;
}
.actor-image::after{
    display: block;
  position: relative;
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  margin-top: -150px;
  height: 150px;
  width: 100%;
  content: '';
}

.information{
    margin: 20px;
}
.information span{
    font-size: 1.3rem;
    font-weight: 900;
}
.information p{
    width: 300px;
    margin-top: 10px;
}
.poster-cast{
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}
.cast-container{
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.cast-member{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.cast-member-image{
    height: 100%;    
}
.cast-member-image img{
    width: 205px;
    height: 298px;
    border-radius: 20px;
}
.cast-member-overview{
    background-color: white;
    border-radius: 10px;
    color: black;
}

#cast-full{
    text-decoration: none;
    color: inherit;
}
#cast-full:hover{
    color: yellow;
}
.similar-container{
    display:flex;
    flex-direction: row;
    overflow-x:scroll;
}



@media (max-width: 800px){
    .poster-info-container{
        padding-top: 10px;
        padding:0;
    }
    .similar-section{
        padding: 0;
        text-align: center;
        margin-top: 20px;
    }
    .poster-info{
        text-align: center;
    }
    hr{
        width: 40%;
        margin: 0 auto;
    }
    
}

@keyframes slideIn{
    0%{ 
        opacity: 0; 
    }
    100%{
         opacity: 1; 
        }
}

@keyframes ratingSlide{
    0%{ 
        opacity: 0; 
    }
    100%{
         opacity: 1; 
        }
}