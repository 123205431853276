.results-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin: 0 auto;
    width: 800px;
}
.Searchpage-section h1{
    margin-top: 30px;
}
@media(min-width: 1000px){
    .Searchpage-section h1{
        text-align: center;
    }
}
@media(max-width: 930px){
    .Searchpage-section{
        padding: 0;
        text-align: center;
    }
    .results-container{        
        display:flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
    }
}