#top-popular-title{
    font-weight: 300;
    margin-top: 30px;
}
.rated-movies-container{
    max-width: 920px;
    margin: auto;
}
.popular-movies-container{
    max-width: 920px;
    margin: auto;
}

.pagination-container{
    width: 100%;
}
.pagination-links{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 0 auto;

}
.pagination-link{
    margin: 10px;
    border: .5px solid white;
    padding: 10px;
    font-size: 1.2rem;
    text-decoration: none;
    color: inherit;
}

@media(min-width: 1360px){
    .rated-movies-container{
        max-width: 1370px;
        margin: 0 auto;
    }
    .popular-movies-container{
        max-width: 1370px;
        margin: 0 auto;
    }
}

@media(max-width: 900px){
    .rated-movies-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .popular-movies-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}