.Sidenav-container{
    width: 70%;
    max-width: 400px;
    background: #0f2133ec; 
    position: fixed;
    top: 0;
    right:0;
    z-index: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.4s ease-out;
}

.Sidenav-item{
    margin: 20px;
    text-decoration: none;
    color: inherit;
    font-size: 1.2rem;
    font-family: 'Dosis', sans-serif;
}

.Sidenav-container.open{
    transform: translateX(0);
}