.noDisplay{
    display: none;
}
.search-btn{
    text-decoration: none;
    color: inherit;
    background-color: #233a50;
    text-align: center;
    border-radius: 20px;
    padding: 5px;
    width: 120px;
    margin: 0 auto;
    margin-top: 5px;
}
.search-btn:hover{
    background-color: #0f2133;
}
.Searchbar-container{
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    width: 70%;
}
.Searchbar-container i {
    position: absolute;
    top: 8px;
    outline: none;
    left: 10px;
}

.Searchbar-container input{
    font-size: 1rem;
    border-radius: 10px;
    padding: 3px 40px;
    outline: none;
    position: relative;
    background-color: #233a50;
    color:#abb7c4;
    border:2px solid#020d18;
    
}
.searchbar-results{   
    position: absolute;
    width: 100%;
    padding: 0px 40px;
    background-color: #233a50;
    border:4px solid #020d18;
    border-radius: 10px;
    top:45px;
    z-index: 100;
}

.searchbar-results ul {
    list-style: none;
}

@media(max-width: 710px){
    .searchbar-results{
        min-width: 200px;
    }
    .Searchbar-container i{
        left: 10px;
        top: 9px;
    }
}