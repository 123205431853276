.Landing-section{
    padding:0;
}
.latest-movies-section, .now-playing-section, .upcoming-section{
    text-align: center;
    padding: 50px 100px;
    color: rgb(216, 216, 216);
}
.top-rated-section{
    text-align: center;
    color: rgb(216, 216, 216);
}
.landing-decoration-container{
    height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.top-rated-section{    
    width: 100%;         
    /* background: rgba(0, 0, 0, 0.534); */
    border-radius: 80px 80px 30px 30px;
    position:absolute;
    bottom: 0;
}
#search_landing_title{
    font-size: 2rem;
    font-weight: 300;
    color: rgb(216, 216, 216);
}
.landing-search{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
    max-width: 400px;
    margin: 0 auto;
    transform: translateY(-140%)            ;
    padding-bottom: 1.5rem;
}

footer{
    display: flex;
    width: 100%;
    height: 2rem;
    justify-content: space-between;
    padding: 40px 15px;    
}
footer span{
    padding-bottom: 20px;
}
.horizontal-line{
    border-top: 3px solid yellow;
    padding: 5px;
}



@media screen and (max-width: 955px){
    .latest-movies-section, .now-playing-section, .upcoming-section{
        padding:50px 0px;
    
    }
}

@media screen and (max-width: 1761px){
    .Landing-container {
        padding: 10vh 0;        
    }
}

@media screen and (min-width : 1400px) {
    
    .landing-movies-container{
        max-width: 1500px;
    }
    
}

