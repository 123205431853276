.absolute-trailerPage{
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.824);
    z-index: 1000000;
}
.trailers-title{
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: space-between;
}
.trailers-title > *{
    margin: 20px;
}

.exitIcon{
    
    position: relative;
    right:0;
}
@media(min-width: 1100px){
    .trailer-trailers-container{        
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
}
